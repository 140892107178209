body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  
  button{
    padding:5px;
  }
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Mekanus ADF Std', 'Roboto', serif;  
    /* font-family: 'Mekanus ADF Titling Std', sans-serif; */
  }
  * a{
    text-decoration: none;
  }

  .App {
    text-align: center;
    position: relative;
  }
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
.top-navbar{
    top: 0;
    position: -webkit-sticky;
    position: sticky;
    background-color: aliceblue;
    z-index: 1000;
}
.btn-delete{
  background-color: tomato;
}
.btn-delete:hover{
  background-color: red;
}
.footer-section{
  bottom: 0;
}
button {
  padding: 12px 10px;
  border: none;
  border-radius: 5px;
  background-color: #0066cc;
  color: #fff;
  font-size: 1.3rem;
  cursor: pointer;
  transition: background-color 0.3s;
}


*{
    list-style-type: none;
}

.top-nav{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    height: 6rem;
    z-index: 10;
    box-shadow: 0 0 15px rgb(7, 54, 72);
}

.nav-links{
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 1rem 1.2rem;
    font-size: 1.5rem;
}

.nav-links>a{
    margin: 0 1rem;
    text-decoration: none;
    color: rgb(12, 88, 129);
}

.nav-links>a>span{
    color: rgb(167, 14, 14);
    font-weight: bolder;
    text-shadow: 1px 1px 3px black;
}

.nav-links>a:hover{
    text-decoration: underline;
    text-underline-offset: 8px;
    
}

.nav-links>button{
    padding: 0.7rem 1.2rem;
    background-color: rgb(21, 89, 93);
    color: white;
    border: none;
    border-radius: 1rem;
    font-size: 1.2rem;
    cursor: pointer;
}

.nav-links>button:hover{
    background-color: rgb(16, 84, 88);
    color: white;
}

.logo-div{    
    object-fit: cover;
}
.mobile-menu{
    float: right;
    
}

.menu-link-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}


@media screen and (max-width:960px) {
  
    .nav-links{
        display: none;
    }
    .logo-div{
        font-size: 1.5rem;
    }

@media screen and (max-width:600px) {
    .top-nav{
        padding: 1rem;
    }
}
}
.mobile-menu{
    width: 250px;
    display: none;
}
.mobile-menu>img{
    width: 40px;
    background-color: lightgrey;
    padding: 5px;
    border-radius: 5px;
    float: right;
    cursor: pointer;
}

.mobile-menu>img:hover{
    background-color: lightgrey;
    padding: 5px 10px;
}

.mobile-nav-links{
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    font-size: 1.5rem;
    width: 250px;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.716);
    /* display: none; */
}

.mobile-nav-links>a{
    padding: 1.2rem;
    width: 100%;
    text-decoration: none;
    color: rgb(209, 226, 235);
    border-bottom: 1px solid white;
}
.mobile-nav-links>a:last-child{
    border-bottom: none;
}

.mobile-nav-links>a>span{
    color: rgb(167, 14, 14);
    font-weight: bolder;
    width: 100%;
    text-shadow: 1px 1px 3px black;
}

.mobile-nav-links>a:hover{
    text-underline-offset: 8px;
    background-color: black;
    width: 100%;
    
}

.mobile-nav-links>button{
    padding: 0.7rem 1.2rem;
    background-color: rgb(21, 89, 93);
    color: white;
    border: none;
    border-radius: 1rem;
    font-size: 1.2rem;
    cursor: pointer;
}

.mobile-nav-links>button:hover{
    background-color: rgb(16, 84, 88);
    color: white;
}



@media screen and (max-width:960px) {
    .mobile-menu{
        display: block;
    }
    .mobile-nav-links{
        display: none;
    }
}
.logo-self{
    width: 200px;
    object-fit: cover;
}

.logo-self>img{
    width: 100%;
}

@media screen and (max-width:800px){
    .logo-self{
        width: 120px;
    }
    
}
.footer{
    bottom: 0;
}
.footer-top{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 2rem;
    height: -webkit-max-content;
    height: max-content;
    grid-column-gap: 1rem;
    -webkit-column-gap: 1rem;
            column-gap: 1rem;
    background-color: #dbe5ed;
    box-shadow: 5px 0px 15px black;
}
.footer-top-left{
    width: 70%;
    height: 100px;
    text-align: left;
    font-size: 1.2rem;
    line-height: 1.5rem;
    border-right: 2px solid #fff;
}
.left-top-footer{
    width: 50%;
    display: flex;
    flex-direction: row;
    grid-column-gap: 2rem;
    -webkit-column-gap: 2rem;
            column-gap: 2rem;
}
.right-top-footer{
    width: 50%;
    display: flex;
    flex-direction: row;
    grid-column-gap: 2rem;
    -webkit-column-gap: 2rem;
            column-gap: 2rem;
}
.footer-top-mid{
    width: 40%;
    height: 100px;
    display: flex;
    flex-direction: column;
    font-size: 1.2rem;
    line-height: 1.5rem;
    border-right: 2px solid #fff;
}

.footer-top-right{
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 60%;
    padding-left: 2rem;
    font-size: 1.2rem;
}

.footer-top-right>p>img{
    width: 30px;
    cursor: pointer;
}
.footer-top-right>p>img{
    width: 30px;
    cursor: pointer;
}

.social-icons{
    display: flex;
    flex-direction: row;
    grid-column-gap: 2rem;
    -webkit-column-gap: 2rem;
            column-gap: 2rem;
    align-items: center;
}
.social-icons>img{
    width: 30px;
    margin-top: 1rem;
    cursor: pointer;
}

.footer-bottom{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footer-bottom>span{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-row-gap: 1rem;
    row-gap: 1rem;
    font-size: 1.2rem;
    padding: 2rem;
    color:  #3a91cb;
    background-color: #000;
    width: 100%;
}

.footer-bottom>p{
    width: 100%;
    background-color: black;
    color: white;
    padding: 1rem;
}

.admin-panel{
    color: yellow;
}
.footer-last{
    display: flex;
    width: 90%;
    justify-content: space-between;
    align-items: center;
}

@media screen and (max-width:1230px) {
    .footer-top{
        flex-direction: column;
        grid-row-gap: 2rem;
        row-gap: 2rem;
        justify-content: center;
        align-items: center;
    }
    .left-top-footer{
        width: 90%;
        justify-content: center;
        align-items: center;
    }
    .footer-top-left, .footer-top-mid{
        border: none;
    }
    .right-top-footer{
        width: 90%;
        justify-content: center;
        align-items: center
    }
}

@media screen and (max-width:760px) {
   .footer-top{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .left-top-footer{
        width: 90%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .footer-top-left h3 ,p{
        text-align: center;
    }
    .right-top-footer{
        background-color: #fff;
        width: 90%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1rem;
    }

    .logo{
        width: 100%;
       display: flex;
       justify-content: center;
    }

    .logo, .footer-top-left, .footer-top-right, .footer-top-mid{
        width: 100%;
        border-right: none;
        padding-bottom: 1rem;
        align-items: center;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }

    .footer-top-right{
        border: none;
    }
    .social-icons{
        justify-content: center;
    }
}
.header-container{
 
    background: url(/static/media/hero-banner.123287ac.jpg);
    background-repeat: no-repeat;
    box-shadow: 0 0 15px black;
}

.header-container>.bottom-nav{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5rem 2rem;
}

.header-container>.bottom-nav>.hero-text{
    width: 50%;
    float: right;
}

.header-container>.bottom-nav>.hero-text>h1{
    font-family: 'Mekanus ADF Titling Std', sans-serif;
    color: white;
    font-size: 5.5rem;
    text-shadow: 2px 3px 2px black;
}

.header-container>.bottom-nav>.hero-text>.text-text, .hero-sub{
    font-style: italic;
    color: cadetblue;
    font-size: 2rem;
    text-shadow: 2px 3px 2px black;
    border-radius: .5rem;
    padding: 1rem;
}

.header-container>.bottom-nav>.hero-text>span>p>small{
    color: aliceblue;
}

.btn-appointment{
    margin-top: 2rem;
    padding: 1rem 2rem;
    background-color: rgb(24, 60, 62);
    color: white;
    border: none;
    border-radius: 1rem;
    font-size: 1.5rem;
    box-shadow: 2px 2px 15px aliceblue;
    cursor: pointer;
}

.btn-appointment:hover{
    background-color: rgba(0, 0, 0, 0.071);   
}

@media screen and (max-width:960px) {
    .header-container>.bottom-nav{
        flex-direction: column;
    }
    .hero-image{
        display: none;
    }
    .header-container>.bottom-nav>.hero-text{
        width: 100%;
    }
}
.section-1{
    display: flex;
    flex-direction: row;
    grid-gap: 2rem;
    gap: 2rem;
    justify-content: space-around;
    align-items: center;
    font-size: 2rem;
    text-align: left;
    padding: 1rem 2rem;
    box-sizing: border-box;
}

.section-1-left{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.section-1-left .left-top-section-text{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  grid-column-gap: 2rem;
  -webkit-column-gap: 2rem;
          column-gap: 2rem;
  margin-top: -13rem;
  padding: 0 3rem;
}
.section-1-left h1{
  font-size: 12rem;
  color: aliceblue;
}
.section-1-left .left-top-section-text p{
  margin-top: -5rem;
  line-height: 2rem;
  font-size: 20px;
  color: rgb(99, 102, 105);
  text-align: justify;
  flex: 3 1;
}

.section-left{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    color: rgb(101, 112, 122);
    position: relative;  
    font-size: 18px;
}
.section-content{
  margin-top: -10rem;
  position: absolute;
}
.section-1-left .left-top-section-text .mid-content{
  flex: 2 1;
  object-fit: cover;
  margin-bottom: 2rem;
}
.section-1-left .left-top-section-text{
  /* display: none; */
  color: rgb(52, 74, 94);
  font-size: 22px;
}
.mid-content img{
  width: 100%;
}


@media (prefers-reduced-motion: no-preference) {
    .section-1-left>img{
      -webkit-animation: med-spin infinite 15s linear;
              animation: med-spin infinite 15s linear;
    }
  }

@-webkit-keyframes med-spin {
    from {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(-360deg);
              transform: rotate(-360deg);
    }
  }

@keyframes med-spin {
    from {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(-360deg);
              transform: rotate(-360deg);
    }
  }

  @media screen and (max-width:960px) {
    .section-1-right{
      display: none;
    }
    .section-1-left h1{
      display: none;
    }
    .section-left{
      width: 100%;
    }
    .section-1-left{
      width: 100%;
      margin-top: 2rem;
    }
    .section-2-left{
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      grid-row-gap: 2rem;
      row-gap: 2rem;
    }
    .section-1-left .left-top-section-text{
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding: 0 2%;
      margin-top: 12%;
    }
    .section-1-left .left-top-section-text p span{
      color: rgb(27, 51, 73);
      font-weight: 600;
      font-size: 22px;
    }
    .section-1-left .left-top-section-text P, .section-1-left .left-top-section-text .mid-content {
      width: 100%;
    }
  }
.quote-main{
    padding: 5rem;
    background-color: cornflowerblue;
    color: aliceblue;
}
.quote-main p{
    font-size: 2rem;
}
.main-event-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
     background-color: rgb(13, 48, 83);
     padding: 2rem;
}

.left-content{
    width: 40%;
    font-weight: 500;
    font-size: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: aliceblue;
    padding: 0 2rem;
}
.right-content{
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem;
    border-left: 1px solid #baadad;
}
.right-content p {
    font-size: 2rem;
    text-align: center;
    color: aliceblue;
    margin-bottom: 2rem;
    width: 80%;
    padding: 5px;
}
.right-content section {
    display: flex;
    flex-direction: row;
    grid-column-gap: 3rem;
    -webkit-column-gap: 3rem;
            column-gap: 3rem;
    align-items: center;
    width: 65%;
    justify-content: center;
    padding: 1rem;
}
.right-content section span {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.right-content section span h1 {
    font-size: 5.6rem;
    font-weight: normal;
    color: white;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
.right-content section span h4 {
    font-size: 2rem;
    font-weight: normal;
    color: white;
}

@media screen and (max-width:840px) {
    .main-event-container{
        flex-direction: column;
        grid-row-gap: 2rem;
        row-gap: 2rem;
        padding: 0;
    }
    .left-content{
        width: 100%;
        border: none;
        padding: 2rem ;

    }
    .right-content{
        width: 100%;
        border: none;
        background-color: black;
        padding: 2rem 0;
    }
}
.main-content{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 2rem
}

.imageset{
    flex: 2 1;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 2rem;
    gap: 2rem;
}
.imageset>span{
    width: 100%;
}
.imageset>span>img{
    width: 100%;
}

.first-img{
    flex: 1 1;
    float: right;
    margin-right: 2rem;
}
.first-img>img{
    width: 100%;
}

/* Top Section styling */

.top-section{
    background: #347dc2;
    margin-bottom: 2rem;
    color: #fff;
    padding: 5rem 3rem;
    font-size: 1.3rem;
    line-height: 2rem;
}
.top-section>h2{
    margin-bottom: 2rem;
}
.top-section>p{
    text-align: justify;
    line-height: 1.8rem;
}

.top-section>p>li{
    padding-left: 2rem;
    list-style: square;
}


@media screen and (max-width:480px) {
    .imageset{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
}


@media screen and (max-width:760px) {
    .main-content{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-right: 2rem;
    }

    .first-img{        
        display: flex;
        flex-direction: column;
        grid-row-gap: 2rem;
        row-gap: 2rem;
        justify-content: center;
        align-items: center;
        margin-right: 0;
        margin-bottom: 2rem;
    }
    .first-img>img:first-child{
        display: none;
    }
    .top-section>p{
        -webkit-columns: 1;
                columns: 1;
    }
}

.About-us{
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
}

.about-right-sidebar{
    float: right;
    width: 300px;
    height: 100%;
    position: -webkit-sticky;
    position: sticky;
    top: 5.5rem;
}

.about-right-sidebar>img{
    width: 100%;
}

.top-image{
    width: 100%;
    height: 350px;
    overflow: hidden;
}

.top-image>h1{
    font-size: 10rem;
    text-align: center;
    color: rgb(236, 241, 245);
}
.about-container{
    display: flex;
    flex-direction: row;
    grid-gap: 2rem;
    gap: 2rem;
    padding: 2rem 4rem;
    margin-top: -15rem;
}

.about-left{
    width: 80%;
    text-align: left;
    font-size: 18px;
    line-height: 25px;
    margin-right: 2rem;
}
.about-doc{
    margin-bottom: 1rem;
    text-align: justify;
}
.about-doc>h2{
    margin-bottom: 1rem;
}
.about-centre>h2{
    margin-bottom: 1rem;
}
.about-centre{
    text-align: justify;
}
.about-doc>p>span{
    width: 180px;
    float: left;
    object-fit: cover;
    margin-right: 1rem;
}
.about-doc>p>span>img{
    width: 100%;
}
.about-centre ul{
    margin-left: 3rem;

}
.about-centre ul li{
    list-style-type: square;
}

@media screen and (max-width:764px) {
    .about-container{
        flex-direction: column-reverse;
        align-items: center;
        padding: 1rem;
    }
    .about-right-sidebar{
        top: -25rem;
    }

    .about-centre, .about-doc{
        line-height: 35px;
    }
    .top-image>h1{
        font-size: 7rem;
    }

    .about-right-sidebar>img{
        display: none;
    }
    .about-left{
        margin-right: 0;
    }
  
}
.Mission, .Vision{
    width: 100%;
    color: grey;
    font-size: 18px;
    font-style: italic;
    padding: 1rem 0;
    text-align: justify;
}
.Mission>h2{
    font-weight: lighter;
}

.Vision>h2{
    font-weight: lighter;
}
.team-container{
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
}
.team-content{
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    grid-gap: 2rem;
    gap: 2rem;
}
.team-member{
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    width: 250px;
    /* box-shadow: 2px 1px 30px black; */
    padding: 1rem;
    border-radius: 1rem;
}
.team-content>.team-member>img{
    width: 150px;
    height: 150px;
    border-radius: 100%;
    border: 2px lightgray solid;
    margin-bottom: 1rem;
}
.team-member-content>article>span{
    font-style: italic;
}
.recent-posts {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 10%;
}

.recent-main {
  width: 100%;
}

.recent-main img {
  width: 100%;
}

.heading1 {
  width: 100%;
  font-size: 20px;
  text-align: justify;
}

.sidebar-post{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-radius: 1rem;
  padding: 1rem;
  grid-column-gap: 1rem;
  -webkit-column-gap: 1rem;
          column-gap: 1rem;
}

.sidebar-post .post-image {
  width: 100px;
  height: 60px;
  object-fit: cover;
}

.post-image img {
  width: 100%;
  height: 55px;
}



@media screen and (max-width: 960px) {
  .recent-posts{
    width: 100%;
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
  }
  .sidebar-post{
    width: 350px;
  }
 
}
.blog-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 98%;
}
.blog-det{
    width: 75%;
}
.blog-sidebar{
    float: right;
    margin-right: 1rem;
    width: 20%;
}

/* BlogDetails styling begins here */
.blog-details {
    width: 70%;
    margin: 0 auto;
    background-color: #e0e7e9;
    padding: 5rem 20px;
  }
  
  .blog-details img {
    max-width: 100%;
  }
  
.blog-details-container{
    transition: 2s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background: #3a3d3e;
    padding: 2rem auto;
}

.blog-details-container article{
    width: 60%;
    background: #fffdfd;
    padding: 1rem 3rem;
    text-align: justify;
    font-size: 1.2rem;
    line-height: 2rem;
}
.blog-details-container article span{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 1rem;
}
.blog-details-container article img{
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}
.blog-details-container article button{
    margin: 2rem 0 5rem 0;
}
.blog-details-container h2{
    color: rgb(7, 102, 170);
    font-size: 4rem;
    text-align: center;
    padding: 2rem 1rem;
    border-bottom: 1px solid #b1b7b9;
}
.blog-details-container article .small-details{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 1rem;;
}
.blog-details-container article .post-content{
    font-size: 20px;
    line-height: 2rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid #b1b7b9;
}
.blog-details-container article .buttons-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
}


.heart-spin-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width:960px) {
    .blog-container{
        display:flex;
        flex-direction:column-reverse;
    }
    .blog-details-container article{
        width: 80%;
    }

    .blog-details-container article img{
        width: 100%;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }
    .blog-sidebar{
        display:flex;
        justify-content:center;
        align-items:center;
        padding: 5%;
        width: 100%;
    }
    .blog-det{
        padding: 5%;
        width: 100%;
    }
}
.heartspin{
    width: 250px;
}

.heartspin>img{
    width: 100%;
    -webkit-animation: heart 2s infinite linear;
            animation: heart 2s infinite linear;
}

@-webkit-keyframes heart {
    from{
        -webkit-transform: rotateY(0deg);
                transform: rotateY(0deg);
    }
    to{
        -webkit-transform: rotateY(360deg);
                transform: rotateY(360deg);
    }    
}

@keyframes heart {
    from{
        -webkit-transform: rotateY(0deg);
                transform: rotateY(0deg);
    }
    to{
        -webkit-transform: rotateY(360deg);
                transform: rotateY(360deg);
    }    
}
.main{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.left-container{
    width: 50%;
}
.left-container>img{
    width: 100%;
    height: 100%;
    /* box-shadow: 0 1px 20px black; */
}

.right-container{
    width: 50%;
    padding: 1rem 0;
}
form {
    display: flex;
    flex-direction: column;
    width: 600px;
    margin: 0 auto;
    font-size: 1.2rem;
  }
  .your-name{
    display: flex;
    flex-direction: row;
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
    width: 100%;
  }

  .your-name>label>input{
    width: 280px;
  }
  
  label {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-bottom: 10px;
  }
  
  input, select {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1.3rem;
    border: 1px solid #4080bc;
  }
  textarea{
    font-size: 1.3rem;
    padding: 1rem;
  }
  
  button {
    padding: 12px 10px;
    border: none;
    border-radius: 5px;
    background-color: #0066cc;
    color: #fff;
    font-size: 1.3rem;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  button:hover {
    background-color: #004b8f;
  }
  
  h1 {
    text-align: center;
    margin-bottom: 20px;
  }

  /* Notification message */
  .success, .failed{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    background-color: #000;
    color: aliceblue;
    height: 3rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 0 1rem;
    position: absolute;
    right: 0;
    font-size: 1.2rem;
  }
  .success p, .failed p {
    font-size: 1.2rem;
  }

  .success span {
    width: 20px;
    height: 20px;
    background-color: green;
    border: none;
    border-radius: 50%;
    margin-right: 1rem;
  }

  .failed span {
    width: 20px;
    height: 20px;
    background-color: red;
    border: none;
    border-radius: 50%;
    margin-right: 1rem;
  }

  /* Notification messages ends here */
  

  @media screen and (max-width:760px){
    .right-container{
      width: 100%;
    }
    .right-container form{
      width: 100%;
    }
  }

  @media screen and (max-width:1230px) {
    .main{
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: url(/static/media/dedoc.8c6b6db7.webp);
      background-size: cover;
      background-repeat: no-repeat;
      padding: 2rem 0;
    }
    .left-container{
      display: none;
    }
    
    form, form>.your-name{
      width: 100%;
      flex-direction: column;
    }
    form>label, form>.your-name>label{
      background-color: #004b8f;
      padding: 0.2rem;
      color: white;
    }
    form>label>input, form>.your-name>label>input{
      width: 100%;
    }
  }
.pagenotfound{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 5rem;
}

.left-section>h1>span{
    font-size: 15rem;
    color: #072e5b;
}

.left-section>h1{
    font-size: 3rem;
}

  .dash {
      display: flex;
      flex-direction: row;
      grid-column-gap: 2rem;
      -webkit-column-gap: 2rem;
              column-gap: 2rem;
      height: 100%;
      width: 100%;
      position: relative;
    }
    .top-title{
      background-color: #1e1c1c;
      color: aliceblue;
      padding: 1rem 0;
    }
    .sidebar {
      padding-top: 100px;
      position: fixed;
      top: 0;
      left: 0;
      width: 200px;
      height: 100%;
      margin-bottom: 200px;
      background-color: #033145;
    }
    
    .sidebar ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }
    .sidebar li:last-child {
      margin-top: 5rem;
      border: none;
      background-color: #000000;
    }
  
    .sidebar a {
      display: block;
      width: 100%;
      text-decoration: none;
      padding: 10px;
      border-radius: 5px;
      color: aliceblue;
      font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
      font-size: 1.2rem;
      transition: background-color 0.3s ease;
      border-bottom: 1px solid #0f5875;
    }
    
    .sidebar a:hover {
      background-color: #2a2c2d;
    }
    .sidebar a:active {
      background-color: #2a2c2d;
    }
    
    /* .dash-left{
        background-color: #034325;
        width: 300px;
    } */
    .dash-right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-left: 200px;
      width: calc(100% - 200px);
    }
    .top-sidebar{
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      grid-row-gap: 1rem;
      row-gap: 1rem;
      padding: 2rem;
      width: 100%;
      color: aliceblue;
      text-align: center;
      background: #1e1c1c;
    }
    .top-sidebar span{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
    .top-sidebar span img{
      width: 60%;
      border-radius: 50%;
  
      object-fit: cover;
      border: 3px lightblue solid;
    }
    
    /* Form styling goes here */
    .form-container{
      width: 70%;
      justify-content: center;
      align-items: center;
    }
    form{
      padding: 2rem 5rem;
      display: flex;
      flex-direction: column;
      grid-row-gap: 0.5rem;
      row-gap: 0.5rem;
      width: 100%;
  
    }
    form div{
      display: flex;
      flex-direction: column;
    }
    input, textarea{
      border: 1px solid lightslategray;
      border-radius: 5px;
      padding: 0.5rem 15px;
      width: 100%;
      color: black;
    }
    label{
      text-align: left;
    }
    button{
      border: none;
      border-radius: 5px;
      color: white;
      background-color: #033145;
      padding: .3rem 0;
      font-size: 1.3rem;
    }
    button:disabled{
      background-color: #2a2c2d;
    }
    button:disabled:hover{
      background-color: #101111;
    }
    button:hover{
      cursor: pointer;
      background-color: #076993;
    }
  
    /* Login and Signup styling */
  .login-main-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh;
  }

  .dashboard-container{
    top: 200px;
    
  }

  .login h1{
    background-color: #fff;
    width: 100%;
    padding: 1rem 0;
    font-size: 2rem;
    border-top: 0.4rem solid lightgrey;
    border-bottom: 0.4rem solid lightgrey;
  }
  
    .login {
      width: 500px;
      background-color:#0e84b7 ;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-bottom: 2rem;
    }
    .signup {
      width: 600px;
      background-color:#0e84b7 ;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    form div p a {
      color: white;
      background-color: #000;
      padding: 2px 3px;
    }
    .bio{
      display: flex;
      flex-direction: row;
      grid-column-gap: 2rem;
      -webkit-column-gap: 2rem;
              column-gap: 2rem;
    }
   
    /* Form styling ends here */
  
    /* Notification message */
    .success, .failed{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      background-color: #000;
      color: aliceblue;
      height: 3rem;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      padding: 0 1rem;
      position: absolute;
      right: 0;
      font-size: 1.2rem;
    }
    .success p, .failed p {
      font-size: 1.2rem;
    }
  
    .success span {
      width: 20px;
      height: 20px;
      background-color: green;
      border: none;
      border-radius: 50%;
      margin-right: 1rem;
    }
  
    .failed span {
      width: 20px;
      height: 20px;
      background-color: red;
      border: none;
      border-radius: 50%;
      margin-right: 1rem;
    }
  
    /* Notification messages ends here */


   /* Main Dashboard Blog Post Styling Begins here  */
   table {
    border-collapse: collapse;
    width: 100%;
    margin: 3rem;
  }
  
  th, td {
    text-align: left;
    padding: 8px 15px;
    border-bottom: 1px solid #8c8484;
  }
  
  th {
    background-color: #e8e3e3;
    font-size: 20px;
  }
  
  tr:hover {
    background-color: #cbc4c4;
  }
  
  button {
    margin-right: 8px;
    padding: 5px 10px;
  }
  

   /* Main Dashboard Blog Post Styling Ends here  */
  .readpost-container{
    width: 70%;
    font-family:Georgia, 'Times New Roman', Times, serif;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
  }

   .readpost-container button:last-child {
    background-color: red;
    color: white;
   }

   .td-image{
    width: 70px;
    object-fit: cover;
   }
   .td-image img{
    width: 100%;
   }
   .readpost-container>.readpost-buttons{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
   }
   .readpost-image{
    width: 70%;
    margin-bottom: 2rem;
   }
   .readpost-image img{
    width: 100%;
   }

   /* Events Display toggle */
   .events-on-off{
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-column-gap: 2rem;
    -webkit-column-gap: 2rem;
            column-gap: 2rem;
    margin-bottom: 3rem;
   }

   .hide-event-comp {
    display: none;
   }
