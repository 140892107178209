
  .dash {
      display: flex;
      flex-direction: row;
      column-gap: 2rem;
      height: 100%;
      width: 100%;
      position: relative;
    }
    .top-title{
      background-color: #1e1c1c;
      color: aliceblue;
      padding: 1rem 0;
    }
    .sidebar {
      padding-top: 100px;
      position: fixed;
      top: 0;
      left: 0;
      width: 200px;
      height: 100%;
      margin-bottom: 200px;
      background-color: #033145;
    }
    
    .sidebar ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }
    .sidebar li:last-child {
      margin-top: 5rem;
      border: none;
      background-color: #000000;
    }
  
    .sidebar a {
      display: block;
      width: 100%;
      text-decoration: none;
      padding: 10px;
      border-radius: 5px;
      color: aliceblue;
      font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
      font-size: 1.2rem;
      transition: background-color 0.3s ease;
      border-bottom: 1px solid #0f5875;
    }
    
    .sidebar a:hover {
      background-color: #2a2c2d;
    }
    .sidebar a:active {
      background-color: #2a2c2d;
    }
    
    /* .dash-left{
        background-color: #034325;
        width: 300px;
    } */
    .dash-right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-left: 200px;
      width: calc(100% - 200px);
    }
    .top-sidebar{
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      row-gap: 1rem;
      padding: 2rem;
      width: 100%;
      color: aliceblue;
      text-align: center;
      background: #1e1c1c;
    }
    .top-sidebar span{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
    .top-sidebar span img{
      width: 60%;
      border-radius: 50%;
  
      object-fit: cover;
      border: 3px lightblue solid;
    }
    
    /* Form styling goes here */
    .form-container{
      width: 70%;
      justify-content: center;
      align-items: center;
    }
    form{
      padding: 2rem 5rem;
      display: flex;
      flex-direction: column;
      row-gap: 0.5rem;
      width: 100%;
  
    }
    form div{
      display: flex;
      flex-direction: column;
    }
    input, textarea{
      border: 1px solid lightslategray;
      border-radius: 5px;
      padding: 0.5rem 15px;
      width: 100%;
      color: black;
    }
    label{
      text-align: left;
    }
    button{
      border: none;
      border-radius: 5px;
      color: white;
      background-color: #033145;
      padding: .3rem 0;
      font-size: 1.3rem;
    }
    button:disabled{
      background-color: #2a2c2d;
    }
    button:disabled:hover{
      background-color: #101111;
    }
    button:hover{
      cursor: pointer;
      background-color: #076993;
    }
  
    /* Login and Signup styling */
  .login-main-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh;
  }

  .dashboard-container{
    top: 200px;
    
  }

  .login h1{
    background-color: #fff;
    width: 100%;
    padding: 1rem 0;
    font-size: 2rem;
    border-top: 0.4rem solid lightgrey;
    border-bottom: 0.4rem solid lightgrey;
  }
  
    .login {
      width: 500px;
      background-color:#0e84b7 ;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-bottom: 2rem;
    }
    .signup {
      width: 600px;
      background-color:#0e84b7 ;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    form div p a {
      color: white;
      background-color: #000;
      padding: 2px 3px;
    }
    .bio{
      display: flex;
      flex-direction: row;
      column-gap: 2rem;
    }
   
    /* Form styling ends here */
  
    /* Notification message */
    .success, .failed{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      background-color: #000;
      color: aliceblue;
      height: 3rem;
      width: fit-content;
      padding: 0 1rem;
      position: absolute;
      right: 0;
      font-size: 1.2rem;
    }
    .success p, .failed p {
      font-size: 1.2rem;
    }
  
    .success span {
      width: 20px;
      height: 20px;
      background-color: green;
      border: none;
      border-radius: 50%;
      margin-right: 1rem;
    }
  
    .failed span {
      width: 20px;
      height: 20px;
      background-color: red;
      border: none;
      border-radius: 50%;
      margin-right: 1rem;
    }
  
    /* Notification messages ends here */


   /* Main Dashboard Blog Post Styling Begins here  */
   table {
    border-collapse: collapse;
    width: 100%;
    margin: 3rem;
  }
  
  th, td {
    text-align: left;
    padding: 8px 15px;
    border-bottom: 1px solid #8c8484;
  }
  
  th {
    background-color: #e8e3e3;
    font-size: 20px;
  }
  
  tr:hover {
    background-color: #cbc4c4;
  }
  
  button {
    margin-right: 8px;
    padding: 5px 10px;
  }
  

   /* Main Dashboard Blog Post Styling Ends here  */
  .readpost-container{
    width: 70%;
    font-family:Georgia, 'Times New Roman', Times, serif;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
  }

   .readpost-container button:last-child {
    background-color: red;
    color: white;
   }

   .td-image{
    width: 70px;
    object-fit: cover;
   }
   .td-image img{
    width: 100%;
   }
   .readpost-container>.readpost-buttons{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
   }
   .readpost-image{
    width: 70%;
    margin-bottom: 2rem;
   }
   .readpost-image img{
    width: 100%;
   }

   /* Events Display toggle */
   .events-on-off{
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 2rem;
    margin-bottom: 3rem;
   }

   .hide-event-comp {
    display: none;
   }