*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Mekanus ADF Std', 'Roboto', serif;  
    /* font-family: 'Mekanus ADF Titling Std', sans-serif; */
  }
  * a{
    text-decoration: none;
  }

  .App {
    text-align: center;
    position: relative;
  }
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
.top-navbar{
    top: 0;
    position: sticky;
    background-color: aliceblue;
    z-index: 1000;
}
.btn-delete{
  background-color: tomato;
}
.btn-delete:hover{
  background-color: red;
}
.footer-section{
  bottom: 0;
}
button {
  padding: 12px 10px;
  border: none;
  border-radius: 5px;
  background-color: #0066cc;
  color: #fff;
  font-size: 1.3rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

