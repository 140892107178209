.logo-self{
    width: 200px;
    object-fit: cover;
}

.logo-self>img{
    width: 100%;
}

@media screen and (max-width:800px){
    .logo-self{
        width: 120px;
    }
    
}