.Mission, .Vision{
    width: 100%;
    color: grey;
    font-size: 18px;
    font-style: italic;
    padding: 1rem 0;
    text-align: justify;
}
.Mission>h2{
    font-weight: lighter;
}

.Vision>h2{
    font-weight: lighter;
}