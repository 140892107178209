.section-1{
    display: flex;
    flex-direction: row;
    gap: 2rem;
    justify-content: space-around;
    align-items: center;
    font-size: 2rem;
    text-align: left;
    padding: 1rem 2rem;
    box-sizing: border-box;
}

.section-1-left{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.section-1-left .left-top-section-text{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  column-gap: 2rem;
  margin-top: -13rem;
  padding: 0 3rem;
}
.section-1-left h1{
  font-size: 12rem;
  color: aliceblue;
}
.section-1-left .left-top-section-text p{
  margin-top: -5rem;
  line-height: 2rem;
  font-size: 20px;
  color: rgb(99, 102, 105);
  text-align: justify;
  flex: 3;
}

.section-left{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: fit-content;
    color: rgb(101, 112, 122);
    position: relative;  
    font-size: 18px;
}
.section-content{
  margin-top: -10rem;
  position: absolute;
}
.section-1-left .left-top-section-text .mid-content{
  flex: 2;
  object-fit: cover;
  margin-bottom: 2rem;
}
.section-1-left .left-top-section-text{
  /* display: none; */
  color: rgb(52, 74, 94);
  font-size: 22px;
}
.mid-content img{
  width: 100%;
}


@media (prefers-reduced-motion: no-preference) {
    .section-1-left>img{
      animation: med-spin infinite 15s linear;
    }
  }

@keyframes med-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(-360deg);
    }
  }

  @media screen and (max-width:960px) {
    .section-1-right{
      display: none;
    }
    .section-1-left h1{
      display: none;
    }
    .section-left{
      width: 100%;
    }
    .section-1-left{
      width: 100%;
      margin-top: 2rem;
    }
    .section-2-left{
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      row-gap: 2rem;
    }
    .section-1-left .left-top-section-text{
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding: 0 2%;
      margin-top: 12%;
    }
    .section-1-left .left-top-section-text p span{
      color: rgb(27, 51, 73);
      font-weight: 600;
      font-size: 22px;
    }
    .section-1-left .left-top-section-text P, .section-1-left .left-top-section-text .mid-content {
      width: 100%;
    }
  }