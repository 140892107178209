.heartspin{
    width: 250px;
}

.heartspin>img{
    width: 100%;
    animation: heart 2s infinite linear;
}

@keyframes heart {
    from{
        transform: rotateY(0deg);
    }
    to{
        transform: rotateY(360deg);
    }    
}