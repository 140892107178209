.main{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.left-container{
    width: 50%;
}
.left-container>img{
    width: 100%;
    height: 100%;
    /* box-shadow: 0 1px 20px black; */
}

.right-container{
    width: 50%;
    padding: 1rem 0;
}
form {
    display: flex;
    flex-direction: column;
    width: 600px;
    margin: 0 auto;
    font-size: 1.2rem;
  }
  .your-name{
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    width: 100%;
  }

  .your-name>label>input{
    width: 280px;
  }
  
  label {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-bottom: 10px;
  }
  
  input, select {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1.3rem;
    border: 1px solid #4080bc;
  }
  textarea{
    font-size: 1.3rem;
    padding: 1rem;
  }
  
  button {
    padding: 12px 10px;
    border: none;
    border-radius: 5px;
    background-color: #0066cc;
    color: #fff;
    font-size: 1.3rem;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  button:hover {
    background-color: #004b8f;
  }
  
  h1 {
    text-align: center;
    margin-bottom: 20px;
  }

  /* Notification message */
  .success, .failed{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    background-color: #000;
    color: aliceblue;
    height: 3rem;
    width: fit-content;
    padding: 0 1rem;
    position: absolute;
    right: 0;
    font-size: 1.2rem;
  }
  .success p, .failed p {
    font-size: 1.2rem;
  }

  .success span {
    width: 20px;
    height: 20px;
    background-color: green;
    border: none;
    border-radius: 50%;
    margin-right: 1rem;
  }

  .failed span {
    width: 20px;
    height: 20px;
    background-color: red;
    border: none;
    border-radius: 50%;
    margin-right: 1rem;
  }

  /* Notification messages ends here */
  

  @media screen and (max-width:760px){
    .right-container{
      width: 100%;
    }
    .right-container form{
      width: 100%;
    }
  }

  @media screen and (max-width:1230px) {
    .main{
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: url('../../assets/images/dedoc.webp');
      background-size: cover;
      background-repeat: no-repeat;
      padding: 2rem 0;
    }
    .left-container{
      display: none;
    }
    
    form, form>.your-name{
      width: 100%;
      flex-direction: column;
    }
    form>label, form>.your-name>label{
      background-color: #004b8f;
      padding: 0.2rem;
      color: white;
    }
    form>label>input, form>.your-name>label>input{
      width: 100%;
    }
  }