.About-us{
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
}

.about-right-sidebar{
    float: right;
    width: 300px;
    height: 100%;
    position: sticky;
    top: 5.5rem;
}

.about-right-sidebar>img{
    width: 100%;
}

.top-image{
    width: 100%;
    height: 350px;
    overflow: hidden;
}

.top-image>h1{
    font-size: 10rem;
    text-align: center;
    color: rgb(236, 241, 245);
}
.about-container{
    display: flex;
    flex-direction: row;
    gap: 2rem;
    padding: 2rem 4rem;
    margin-top: -15rem;
}

.about-left{
    width: 80%;
    text-align: left;
    font-size: 18px;
    line-height: 25px;
    margin-right: 2rem;
}
.about-doc{
    margin-bottom: 1rem;
    text-align: justify;
}
.about-doc>h2{
    margin-bottom: 1rem;
}
.about-centre>h2{
    margin-bottom: 1rem;
}
.about-centre{
    text-align: justify;
}
.about-doc>p>span{
    width: 180px;
    float: left;
    object-fit: cover;
    margin-right: 1rem;
}
.about-doc>p>span>img{
    width: 100%;
}
.about-centre ul{
    margin-left: 3rem;

}
.about-centre ul li{
    list-style-type: square;
}

@media screen and (max-width:764px) {
    .about-container{
        flex-direction: column-reverse;
        align-items: center;
        padding: 1rem;
    }
    .about-right-sidebar{
        top: -25rem;
    }

    .about-centre, .about-doc{
        line-height: 35px;
    }
    .top-image>h1{
        font-size: 7rem;
    }

    .about-right-sidebar>img{
        display: none;
    }
    .about-left{
        margin-right: 0;
    }
  
}