.header-container{
 
    background: url(../../assets/images/hero-banner.jpg);
    background-repeat: no-repeat;
    box-shadow: 0 0 15px black;
}

.header-container>.bottom-nav{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5rem 2rem;
}

.header-container>.bottom-nav>.hero-text{
    width: 50%;
    float: right;
}

.header-container>.bottom-nav>.hero-text>h1{
    font-family: 'Mekanus ADF Titling Std', sans-serif;
    color: white;
    font-size: 5.5rem;
    text-shadow: 2px 3px 2px black;
}

.header-container>.bottom-nav>.hero-text>.text-text, .hero-sub{
    font-style: italic;
    color: cadetblue;
    font-size: 2rem;
    text-shadow: 2px 3px 2px black;
    border-radius: .5rem;
    padding: 1rem;
}

.header-container>.bottom-nav>.hero-text>span>p>small{
    color: aliceblue;
}

.btn-appointment{
    margin-top: 2rem;
    padding: 1rem 2rem;
    background-color: rgb(24, 60, 62);
    color: white;
    border: none;
    border-radius: 1rem;
    font-size: 1.5rem;
    box-shadow: 2px 2px 15px aliceblue;
    cursor: pointer;
}

.btn-appointment:hover{
    background-color: rgba(0, 0, 0, 0.071);   
}

@media screen and (max-width:960px) {
    .header-container>.bottom-nav{
        flex-direction: column;
    }
    .hero-image{
        display: none;
    }
    .header-container>.bottom-nav>.hero-text{
        width: 100%;
    }
}