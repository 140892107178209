.team-container{
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
}
.team-content{
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2rem;
}
.team-member{
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    width: 250px;
    /* box-shadow: 2px 1px 30px black; */
    padding: 1rem;
    border-radius: 1rem;
}
.team-content>.team-member>img{
    width: 150px;
    height: 150px;
    border-radius: 100%;
    border: 2px lightgray solid;
    margin-bottom: 1rem;
}
.team-member-content>article>span{
    font-style: italic;
}