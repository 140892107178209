.main-content{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 2rem
}

.imageset{
    flex: 2;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 2rem;
}
.imageset>span{
    width: 100%;
}
.imageset>span>img{
    width: 100%;
}

.first-img{
    flex: 1;
    float: right;
    margin-right: 2rem;
}
.first-img>img{
    width: 100%;
}

/* Top Section styling */

.top-section{
    background: #347dc2;
    margin-bottom: 2rem;
    color: #fff;
    padding: 5rem 3rem;
    font-size: 1.3rem;
    line-height: 2rem;
}
.top-section>h2{
    margin-bottom: 2rem;
}
.top-section>p{
    text-align: justify;
    line-height: 1.8rem;
}

.top-section>p>li{
    padding-left: 2rem;
    list-style: square;
}


@media screen and (max-width:480px) {
    .imageset{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
}


@media screen and (max-width:760px) {
    .main-content{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-right: 2rem;
    }

    .first-img{        
        display: flex;
        flex-direction: column;
        row-gap: 2rem;
        justify-content: center;
        align-items: center;
        margin-right: 0;
        margin-bottom: 2rem;
    }
    .first-img>img:first-child{
        display: none;
    }
    .top-section>p{
        columns: 1;
    }
}
