.main-event-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
     background-color: rgb(13, 48, 83);
     padding: 2rem;
}

.left-content{
    width: 40%;
    font-weight: 500;
    font-size: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: aliceblue;
    padding: 0 2rem;
}
.right-content{
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem;
    border-left: 1px solid #baadad;
}
.right-content p {
    font-size: 2rem;
    text-align: center;
    color: aliceblue;
    margin-bottom: 2rem;
    width: 80%;
    padding: 5px;
}
.right-content section {
    display: flex;
    flex-direction: row;
    column-gap: 3rem;
    align-items: center;
    width: 65%;
    justify-content: center;
    padding: 1rem;
}
.right-content section span {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.right-content section span h1 {
    font-size: 5.6rem;
    font-weight: normal;
    color: white;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
.right-content section span h4 {
    font-size: 2rem;
    font-weight: normal;
    color: white;
}

@media screen and (max-width:840px) {
    .main-event-container{
        flex-direction: column;
        row-gap: 2rem;
        padding: 0;
    }
    .left-content{
        width: 100%;
        border: none;
        padding: 2rem ;

    }
    .right-content{
        width: 100%;
        border: none;
        background-color: black;
        padding: 2rem 0;
    }
}