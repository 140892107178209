.blog-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 98%;
}
.blog-det{
    width: 75%;
}
.blog-sidebar{
    float: right;
    margin-right: 1rem;
    width: 20%;
}

/* BlogDetails styling begins here */
.blog-details {
    width: 70%;
    margin: 0 auto;
    background-color: #e0e7e9;
    padding: 5rem 20px;
  }
  
  .blog-details img {
    max-width: 100%;
  }
  
.blog-details-container{
    transition: 2s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background: #3a3d3e;
    padding: 2rem auto;
}

.blog-details-container article{
    width: 60%;
    background: #fffdfd;
    padding: 1rem 3rem;
    text-align: justify;
    font-size: 1.2rem;
    line-height: 2rem;
}
.blog-details-container article span{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 1rem;
}
.blog-details-container article img{
    width: 100%;
    height: fit-content;
}
.blog-details-container article button{
    margin: 2rem 0 5rem 0;
}
.blog-details-container h2{
    color: rgb(7, 102, 170);
    font-size: 4rem;
    text-align: center;
    padding: 2rem 1rem;
    border-bottom: 1px solid #b1b7b9;
}
.blog-details-container article .small-details{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 1rem;;
}
.blog-details-container article .post-content{
    font-size: 20px;
    line-height: 2rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid #b1b7b9;
}
.blog-details-container article .buttons-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
}


.heart-spin-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width:960px) {
    .blog-container{
        display:flex;
        flex-direction:column-reverse;
    }
    .blog-details-container article{
        width: 80%;
    }

    .blog-details-container article img{
        width: 100%;
        height: fit-content;
    }
    .blog-sidebar{
        display:flex;
        justify-content:center;
        align-items:center;
        padding: 5%;
        width: 100%;
    }
    .blog-det{
        padding: 5%;
        width: 100%;
    }
}