.pagenotfound{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 5rem;
}

.left-section>h1>span{
    font-size: 15rem;
    color: #072e5b;
}

.left-section>h1{
    font-size: 3rem;
}