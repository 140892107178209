.footer{
    bottom: 0;
}
.footer-top{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 2rem;
    height: max-content;
    column-gap: 1rem;
    background-color: #dbe5ed;
    box-shadow: 5px 0px 15px black;
}
.footer-top-left{
    width: 70%;
    height: 100px;
    text-align: left;
    font-size: 1.2rem;
    line-height: 1.5rem;
    border-right: 2px solid #fff;
}
.left-top-footer{
    width: 50%;
    display: flex;
    flex-direction: row;
    column-gap: 2rem;
}
.right-top-footer{
    width: 50%;
    display: flex;
    flex-direction: row;
    column-gap: 2rem;
}
.footer-top-mid{
    width: 40%;
    height: 100px;
    display: flex;
    flex-direction: column;
    font-size: 1.2rem;
    line-height: 1.5rem;
    border-right: 2px solid #fff;
}

.footer-top-right{
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 60%;
    padding-left: 2rem;
    font-size: 1.2rem;
}

.footer-top-right>p>img{
    width: 30px;
    cursor: pointer;
}
.footer-top-right>p>img{
    width: 30px;
    cursor: pointer;
}

.social-icons{
    display: flex;
    flex-direction: row;
    column-gap: 2rem;
    align-items: center;
}
.social-icons>img{
    width: 30px;
    margin-top: 1rem;
    cursor: pointer;
}

.footer-bottom{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footer-bottom>span{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 1rem;
    font-size: 1.2rem;
    padding: 2rem;
    color:  #3a91cb;
    background-color: #000;
    width: 100%;
}

.footer-bottom>p{
    width: 100%;
    background-color: black;
    color: white;
    padding: 1rem;
}

.admin-panel{
    color: yellow;
}
.footer-last{
    display: flex;
    width: 90%;
    justify-content: space-between;
    align-items: center;
}

@media screen and (max-width:1230px) {
    .footer-top{
        flex-direction: column;
        row-gap: 2rem;
        justify-content: center;
        align-items: center;
    }
    .left-top-footer{
        width: 90%;
        justify-content: center;
        align-items: center;
    }
    .footer-top-left, .footer-top-mid{
        border: none;
    }
    .right-top-footer{
        width: 90%;
        justify-content: center;
        align-items: center
    }
}

@media screen and (max-width:760px) {
   .footer-top{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .left-top-footer{
        width: 90%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .footer-top-left h3 ,p{
        text-align: center;
    }
    .right-top-footer{
        background-color: #fff;
        width: 90%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1rem;
    }

    .logo{
        width: 100%;
       display: flex;
       justify-content: center;
    }

    .logo, .footer-top-left, .footer-top-right, .footer-top-mid{
        width: 100%;
        border-right: none;
        padding-bottom: 1rem;
        align-items: center;
        height: fit-content;
    }

    .footer-top-right{
        border: none;
    }
    .social-icons{
        justify-content: center;
    }
}