.mobile-menu{
    width: 250px;
    display: none;
}
.mobile-menu>img{
    width: 40px;
    background-color: lightgrey;
    padding: 5px;
    border-radius: 5px;
    float: right;
    cursor: pointer;
}

.mobile-menu>img:hover{
    background-color: lightgrey;
    padding: 5px 10px;
}

.mobile-nav-links{
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    font-size: 1.5rem;
    width: 250px;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.716);
    /* display: none; */
}

.mobile-nav-links>a{
    padding: 1.2rem;
    width: 100%;
    text-decoration: none;
    color: rgb(209, 226, 235);
    border-bottom: 1px solid white;
}
.mobile-nav-links>a:last-child{
    border-bottom: none;
}

.mobile-nav-links>a>span{
    color: rgb(167, 14, 14);
    font-weight: bolder;
    width: 100%;
    text-shadow: 1px 1px 3px black;
}

.mobile-nav-links>a:hover{
    text-underline-offset: 8px;
    background-color: black;
    width: 100%;
    
}

.mobile-nav-links>button{
    padding: 0.7rem 1.2rem;
    background-color: rgb(21, 89, 93);
    color: white;
    border: none;
    border-radius: 1rem;
    font-size: 1.2rem;
    cursor: pointer;
}

.mobile-nav-links>button:hover{
    background-color: rgb(16, 84, 88);
    color: white;
}



@media screen and (max-width:960px) {
    .mobile-menu{
        display: block;
    }
    .mobile-nav-links{
        display: none;
    }
}