.recent-posts {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 10%;
}

.recent-main {
  width: 100%;
}

.recent-main img {
  width: 100%;
}

.heading1 {
  width: 100%;
  font-size: 20px;
  text-align: justify;
}

.sidebar-post{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-radius: 1rem;
  padding: 1rem;
  column-gap: 1rem;
}

.sidebar-post .post-image {
  width: 100px;
  height: 60px;
  object-fit: cover;
}

.post-image img {
  width: 100%;
  height: 55px;
}



@media screen and (max-width: 960px) {
  .recent-posts{
    width: 100%;
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
  }
  .sidebar-post{
    width: 350px;
  }
 
}