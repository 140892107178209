*{
    list-style-type: none;
}

.top-nav{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    height: 6rem;
    z-index: 10;
    box-shadow: 0 0 15px rgb(7, 54, 72);
}

.nav-links{
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 1rem 1.2rem;
    font-size: 1.5rem;
}

.nav-links>a{
    margin: 0 1rem;
    text-decoration: none;
    color: rgb(12, 88, 129);
}

.nav-links>a>span{
    color: rgb(167, 14, 14);
    font-weight: bolder;
    text-shadow: 1px 1px 3px black;
}

.nav-links>a:hover{
    text-decoration: underline;
    text-underline-offset: 8px;
    
}

.nav-links>button{
    padding: 0.7rem 1.2rem;
    background-color: rgb(21, 89, 93);
    color: white;
    border: none;
    border-radius: 1rem;
    font-size: 1.2rem;
    cursor: pointer;
}

.nav-links>button:hover{
    background-color: rgb(16, 84, 88);
    color: white;
}

.logo-div{    
    object-fit: cover;
}
.mobile-menu{
    float: right;
    
}

.menu-link-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}


@media screen and (max-width:960px) {
  
    .nav-links{
        display: none;
    }
    .logo-div{
        font-size: 1.5rem;
    }

@media screen and (max-width:600px) {
    .top-nav{
        padding: 1rem;
    }
}
}